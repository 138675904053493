import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`*`}{`*`}{`*`}{`CrossFit The Ville will be closed today (Saturday) because of the
Marathon.  We will add a 9:00am class at East today so we’ll have
classes at 9:00, 10:00 & 11:00am.  Open gym at East is from
12:00-6:00pm. There is no open gym from 9-12 other than in the upstairs
gym area!`}{`*`}{`*`}{`*`}</strong></p>
    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`Single Arm OHS’s 12-12-12/arm`}</p>
    <p>{`then,`}</p>
    <p>{`For time:\\
100 dumbbell snatches, 50/35lb (35/20 Masters over 55)\\
80-calorie row\\
60 burpees\\
40 muscle-ups`}</p>
    <p>{`Time cap: 20 minutes`}</p>
    <p>{`*`}{`CrossFit Kids today at 9:30am.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start April 30th.  For more info
email Eric at: Fallscitystrength\\@gmail.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      